

import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import { cs } from 'date-fns/locale'
import { format, set } from 'date-fns';
import moment from 'moment';

import '../../react-calendar/src/styles.scss'; // main style file
import '../../react-calendar/src/theme/default.scss'; // theme css file

import { DateRange } from '../../react-calendar/src/components/DateRange/index.js';


const ItemDatePicker = () => {

  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [possibleLengths, setPossibleLengths] = useState(null);
  const [allowNext, setAllowNext] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [partlyDisabledDates, setPartlyDisabledDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledDatesCache, setDisabledDatesCache] = useState([]);
  const [shownDate, setShownDate] = useState(new Date());

  const minLength = 1;
  
  const [state, setState] = useState(
    {
      startDate: window.variables.from ? new Date(moment(window.variables.from, 'DD.MM.YYYY').format('YYYY-MM-DD')) : new Date(),
      endDate: window.variables.to ? new Date(moment(window.variables.to, 'DD.MM.YYYY').format('YYYY-MM-DD')) : new Date(),
      key: 'selection',
    }
  );

  useEffect(() => {
    if (state && state.startDate && state.endDate) {
      const length = moment(state.endDate).diff(moment(state.startDate), 'days')+1;
      if (length < minLength) {
        setState({
          startDate: state.startDate,
          endDate: moment(state.startDate).add(minLength-1, 'days').toDate(),
          key: 'selection',
        });
      }
    }
  }, [state.startDate, state.endDate]);

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    return () => window.removeEventListener("resize", resizeW);
  });



  const fetchCalendarData = (startMonth) => {

    // Do not return to range focus if user is changing month
    if (startMonth) {
      setShownDate(moment(startMonth).toDate());
    }

    if (startMonth) {
      var month = moment(startMonth).format('YYYY-MM');
      const nextMonth = moment(startMonth).add(1, 'months').format('YYYY-MM');

      if (disabledDatesCache.includes(month)) {
        if (disabledDatesCache.includes(nextMonth)) {
          return;
        }
      }
    }
    else {
      var month = moment().format('YYYY-MM');
    }

    
    setLoading(true);

    const endPoint = window.api.product.getCalendarData;
    const url = endPoint + '?productId=' + window.variables.entityId + '&dateFrom=' + month;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        var dd = data.disabledDates.map(function(date) {
          let d=  new Date(date)
          // Set time to 00:00:00 - possible bug in react-calendar
          d.setHours(0,0,0,0);
          return d;

        });

        var pdd = data.partlyDisabledDates.map(function(date) {
          return date;
        });

        // Append to cache
        setDisabledDatesCache([...disabledDatesCache, ...data.months]);
        setDisabledDates([...disabledDates, ...dd]);
        setPartlyDisabledDates([...partlyDisabledDates, ...pdd]);
        setLoading(false);
      });
  }

  useEffect(() => {
      fetchCalendarData();
  }, []);


  // Get possible lengths
  useEffect(() => {
    if (state && state.startDate) {
      const endPoint = window.api.product.getMaxReservationLength;
      const url = endPoint + '?productId=' + window.variables.entityId + '&date=' + moment(state.startDate).format('YYYY-MM-DD');

    if (!state.startDate) return;

    setPossibleLengths(null);
    setLoading(true);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setPossibleLengths(data.possibleLengths);
        setLoading(false);
      });
    }
  }, [state.startDate]);

  
  useEffect(() => {
    if (possibleLengths) {
      if (state && state.endDate && state.startDate) {
        const length = moment(state.endDate).diff(moment(state.startDate), 'days')+1;
        
          if (possibleLengths.length == 0 || !possibleLengths.includes(length)) {
              setAllowNext(false);
          }
          else {
              setAllowNext(true);
          }
      } 
    }
  }, [possibleLengths]);

  // End get possible lengths

  const someInfo = allowNext ? (
      <div className='d-flex justify-content-md-end justify-content-center align-items-center flex-column flex-md-row'>
        <PriceInfo startDate={state.startDate} endDate={state.endDate} />
        <AddToCartButton startDate={state.startDate} endDate={state.endDate}  />
      </div>
  ) : (
    <div className='d-flex justify-content-md-end justify-content-center align-items-center flex-column flex-md-row'>
      <div className='text-danger' style={{marginRight: "1em"}}>Není možné rezervovat na tento termín.</div>
      <AddToCartButton disabled={true}  />
    </div>);

  const customDayContent = (day) => {
    var className = "";
    if (partlyDisabledDates.includes(moment(day).format('YYYY-MM-DD'))) {
      className = "partly-disabled";
    }
    return (
      <div className={className}>
        <span>{format(day, "d")}</span>
      </div>
    )
  }

  const className = loading ? 'componentLoading' : '';

  return (
    <div className={className}>
      <DateRange
        onChange={item => setState(item.selection)}
        locale={cs}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[state]}
        direction={deviceSize < 768 ? "vertical" : "horizontal"}
        disabledDates={disabledDates}
        showMonthAndYearPickers={false}
        minDate={new Date()}
        possibleLengths={possibleLengths}
        dayContentRenderer={customDayContent}
        dragSelectionEnabled={false}
        onShownDateChange={fetchCalendarData}
        shownDate={shownDate}
        minLength={minLength}

        // fix - calendar is focusing on selected range on every render:
      
        
      />  
      {someInfo}
    </div>
  );
}

const AddToCartButton = (props) => {
  const baseLink = window.links.reservation.new;
 
  if (!props.startDate || !props.endDate ) {
    return (
      <div>
        <br/>
        <a type="button" className="btn btn-primary disabled">Přidat do košíku&nbsp;&nbsp;&nbsp;<i className="fas fa-chevron-right"></i></a>
        <br/>
        <br/>
      </div>
    );
  }

  const startDate = moment(props.startDate).format('YYYY-MM-DD');
  const length = moment(props.endDate).diff(moment(props.startDate), 'days')+1;
  
  let url = new URL(baseLink);
  url.searchParams.append('date', startDate);
  url.searchParams.append('length', length);
  url.searchParams.append('productId', window.variables.entityId);
  const urlHref = url.toString();

  return (
    <div>
      <br/>
      <a href={urlHref} type="button" className="btn btn-primary">Přidat do košíku&nbsp;&nbsp;&nbsp;<i className="fas fa-chevron-right"></i></a>
      <br/>
      <br/>
    </div>
  );
}

const PriceInfo = (props) => {
  if (!props.startDate || !props.endDate ) {
    return null;
  }

  const [loading, setLoading] = useState(true);

  const endPoint = window.api.product.getPricing;

  const dateFromFormatted = moment(props.startDate).format('DD. MM. YYYY');
  const dateToFormatted = moment(props.endDate).format('DD. MM. YYYY');

  const url1 = endPoint + '?id=' + window.variables.entityId + '&dateTo=' + moment(props.endDate).format('YYYY-MM-DD') + '&dateFrom=' + moment(props.startDate).format('YYYY-MM-DD');

  const [price, setPrice] = useState(0);
  const [unit, setUnit] = useState('ks');
  
  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
  
    var response = fetch(url1, { signal: signal });
    
    response.then(response => response.json())
      .then(data => {
        setLoading(false);
        setPrice(data.priceTotal);
        setUnit(data.unit);
      }
    )
    .catch(error => {
      
    });
    

    return () => controller.abort();

  }, [props.startDate, props.endDate]);


  if (loading) {
    return (
    <div style={{marginRight: "1em", color: "rgb(64,64,64)"}}>
      Načítám cenu...
    </div>
          );
  }

  if (price == false) {
    return (
    <div style={{marginRight: "1em", color: "rgb(64,64,64)"}}>
      Produkt není dostupný mimo sezónu.
    </div>
          );
  }
    
  return (
    <div style={{marginRight: "1em"}}>
      <br/>
      <div style={{fontSize: "1.2em", fontWeight: "bold"}}>{price} Kč za {unit}</div>
      <div style={{fontSize: "0.8em", color: "rgb(64, 64, 64)"}}>{dateFromFormatted} - {dateToFormatted}</div>
      <br/>
    </div>
  );
}

const domContainer = document.querySelector('#item_date_picker_container');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(ItemDatePicker));


